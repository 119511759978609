.tippy-content div p {
    margin-bottom: 0 !important;
}

.c-form-section-group {
    overflow: hidden;
}

.tippy-tooltip {
    text-align:left;
}
.center-header .c-section-heading__title-text,.center-header .c-section-heading__subtitle{
    text-align:center;
    display:block;
    width:100%;
}